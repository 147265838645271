:root {
  --dashboard-overlay-z-index: 2;
  /* Legacy color variables for room content */
  --main-color: #212c42; /* 212c42 */
  --avatar-border: #545454;
  --moto-bg-color: #000f2b; /* 000f2b 850404 292c2e 22282b*/
  --hacker-green: #88cc14;
  --cool-red: #a20606;
  --logged-out-bg: #111b29; /* 111b29 091b35 18283e */
  --navbar-main-height: 85px;
  --darkblue: #1c2331;
  --darkblue-bg-color: #1c2538;
  --optional-btn-color: #e9e9e9;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
